import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompBlockCols from "../../components/MainStats/CompBlockCols"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class RonaldoSportingStats extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Cristiano Ronaldo Sporting CP Goals and Stats"}
          description={`Look back on Cristiano Ronaldo's early career, with all his goals, assists and other stats for Sporting CP in Portugal.`}
          canonicalPath={`/all-time-stats/ronaldo-sporting-cp-stats/`}
        />

        <h1>
          Ronaldo Sporting CP Goals and Stats
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Al Nassr Stats', 'url': '/all-time-stats/ronaldo-al-nassr-stats/' },
            { 'name': 'Juventus Stats', 'url': '/all-time-stats/ronaldo-juventus-stats/' },
            { 'name': 'Real Madrid Stats', 'url': '/all-time-stats/ronaldo-real-madrid-stats/' },
            { 'name': 'Man Utd Stats', 'url': '/all-time-stats/ronaldo-manchester-united-stats/' },
            { 'name': 'Sporting CP Stats', 'url': 'none', 'active': true }
          ]}
        />

        <CompBlockCols>

          <CompetitionBlock competition="All Time Sporting CP" team="Sporting CP" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time Primeira Liga" compTitle="Primeira Liga" team="Sporting CP" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Champions League - Sporting CP" compTitle="Champions League" team="Sporting CP" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="UEFA Cup - Sporting CP" compTitle="UEFA Cup" team="Sporting CP" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time Taça de Portugal" compTitle="Taça de Portugal" team="Sporting CP" mdata={mdata} rdata={rdata} />

        </CompBlockCols>

        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />

          

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/sporting/"}}) {
      edges {
        node {
          id
          competition
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/sporting/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
  }
`

export default RonaldoSportingStats
